<template>
    <section class="cooperationPartners-container">
        <div class="header-container">
            <h2>Samarbetspartners</h2>
            <p :class="{ large: viewWidth > sm, small: viewWidth <= sm }">
                Få ut mer av acconomy genom våra samarbetspartners.
            </p>
        </div>
        <div class="cooperation-grid-container">
            <div v-for="item in data" :key="item.id" class="cooperation-card">
                <img :src="require('@/assets/img/cooperationPartners/' + item.src)" :alt="item.alt" />
                <p :class="{ large: viewWidth > sm, small: viewWidth <= sm }">{{ item.text }}</p>
                <div class="link-container">
                    <inline-svg :src="`${$svg.getSvgUrl('arrow-right.svg')}`"/>
                    <a class="link" :href="item.link" target="_blank">Läs mer</a>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import breakpoints from "@/constants/breakpoints";
import cooperationPartners from "@/json/cooperationPartners.json";
export default {
    name: "CooperationPartners",

    data() {
        return {
            data: cooperationPartners
        };
    },

    computed: {
        sm: () => breakpoints.sm,
        viewWidth() {
            return this.$store.state.base.viewWidth;
        },

    },
};
</script>